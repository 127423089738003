import { Link, useNavigate } from "react-router-dom";
import styles from "./Error.module.css";
import { useEffect } from "react";

const Error = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.error}>
      <h1>Error 404 - page not found</h1>
      <p>
        You will be redirected to the main page shortly. If nothing happens,{" "}
        <Link to="/">click here</Link>.
      </p>
    </div>
  );
};

export default Error;
