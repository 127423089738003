import styles from "./DescriptionButton.module.css";

const DescriptionButton = ({
  mobileDescriptionShow,
}: {
  mobileDescriptionShow: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div
      className={styles["description-button"]}
      onClick={mobileDescriptionShow}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default DescriptionButton;
