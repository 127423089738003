import styles from "./GalleryContainer.module.css";

type Props = {
  children: React.ReactNode;
  horizontal?: boolean;
};

const GalleryContainer = ({ children, horizontal }: Props) => {
  const classes =
    styles["gallery-container"] + (horizontal ? ` ${styles.horizontal}` : "");
  return <div className={classes}>{children}</div>;
};

export default GalleryContainer;
