import Gallery from "./Gallery/Gallery";
import Content from "./Content/Content";
import { ArticleContent, GalleryTuple } from "../../models/article-model";
import styles from "./Right.module.css";
import useScreenResolution from "../../hooks/use-resolution";

type ArticleContentProps = {
  content?: ArticleContent;
  gallery?: GalleryTuple[];
  horizontal?: boolean;
  mobileDescriptionShow: React.MouseEventHandler<HTMLDivElement>;
  mobileGalleryActive: boolean;
};

const Right = (props: ArticleContentProps) => {
  const { height } = useScreenResolution();
  return (
    <div className={styles.right} style={{ height: height }}>
      {props.content && (
        <Content
          inputContent={props.content}
          mobileDescriptionShow={props.mobileDescriptionShow}
          mobileGalleryActive={props.mobileGalleryActive}
        />
      )}
      {props.gallery && (
        <Gallery
          gallery={props.gallery}
          horizontal={props.horizontal}
          mobileDescriptionShow={props.mobileDescriptionShow}
          mobileGalleryActive={props.mobileGalleryActive}
        />
      )}
    </div>
  );
};

export default Right;
