import {
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import Button from "../Button/Button";
import styles from "./Navigation.module.css";

type Props = {
  mobileGalleryClick: React.MouseEventHandler<HTMLDivElement>;
};

const Navigation = ({ mobileGalleryClick }: Props) => {
  return (
    <div className={styles.nav}>
      <Button icon={faArrowCircleLeft} link="/">
        Back <span className={styles.track}>on track</span>
      </Button>
      <Button
        icon={faArrowCircleRight}
        className={styles["gallery-right"]}
        onClick={mobileGalleryClick}
        textBefore
      >
        Gallery
      </Button>
      <Button
        icon={faArrowCircleDown}
        className={styles["gallery-down"]}
        onClick={mobileGalleryClick}
        textBefore
      >
        Gallery
      </Button>
    </div>
  );
};

export default Navigation;
