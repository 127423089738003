import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Link } from "react-router-dom";

import styles from "./Button.module.css";

type Props = {
  icon: IconDefinition;
  link?: string;
  textBefore?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const Button = ({
  icon,
  link,
  textBefore,
  children,
  className,
  onClick,
}: Props) => {
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (onClick) {
        onClick(
          event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>
        );
      }
    };

    if (iconRef.current && onClick) {
      const currentRef = iconRef.current;
      currentRef.addEventListener("click", handleClick);

      return () => {
        currentRef.removeEventListener("click", handleClick);
      };
    }
  }, [onClick]);

  const content = (
    <div
      className={styles.button + (className ? ` ${className}` : "")}
      ref={iconRef}
    >
      {textBefore && <p>{children}</p>}
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      {!textBefore && <p>{children}</p>}
    </div>
  );

  if (link) {
    return <Link to={link}>{content}</Link>;
  } else {
    return content;
  }
};

export default Button;
