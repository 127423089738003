import styles from "./GalleryItem.module.css";

type Props = {
  horizontal: boolean;
  image: string;
  description?: string;
};

const GalleryItem = ({ horizontal, image, description }: Props) => {
  const classes = horizontal
    ? styles.galleryItem
    : styles["galleryItem-vertical"];

  const fileType = image.substring(image.length - 3);

  let content: JSX.Element;
  if (fileType === "mp4") {
    content = (
      <video
        className={styles.image}
        autoPlay
        playsInline
        loop
        muted
      >
        <source src={image} type="video/mp4" />
        {description}
      </video>
    );
  } else {
    content = (
      <img
        src={image}
        alt={description ? description : image}
        className={styles.image}
      />
    );
  }

  return (
    <div className={classes}>
      {content}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default GalleryItem;
