import { Link } from "react-router-dom";

import { MetroData, StationType } from "../../../models/metro-stop";
import MetroStop from "./MetroStop";

import styles from "./MetroLine.module.css";

const MetroLine = (props: { line: MetroData[]; label?: boolean }) => {
  let isFirst = true;

  const content = props.line.map((item) => {
    let station: StationType = item.station;
    if (item.type && isFirst) {
      if (!station) {
        station = "blank";
      }
      isFirst = false;
    }

    const content = props.label ? (
      <Link to={`/${item.content!.replace(/\W/g, "_")}`}>{item.content}</Link>
    ) : (
      item.content
    );

    return (
      <MetroStop key={item.id} id={item.id} type={item.type} station={station}>
        {content}
      </MetroStop>
    );
  });

  const classes = `${styles.line} ${props.label && styles.label}`;
  return <div className={classes}>{content}</div>;
};

export default MetroLine;
