import { PropsWithChildren } from "react";
import styles from "./Customers.module.css";

export const Customers = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <div className={styles.customers}>
      <h2>My Clients</h2>
      <div className={styles['customer-list']}>{children}</div>
    </div>
  );
};
