import styles from "./CustomerLogo.module.css";

export interface CustomerLogoProps {
  logoUrl: string;
  url?: string;
  name?: string;
}

export const CustomerLogo = (props: CustomerLogoProps) => {
  const { url, logoUrl: imageUrl, name } = props;
  return (
    <a
      className={styles["customer-logo"]}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <img src={`${process.env.REACT_APP_IMAGES_ROOT || ''}${imageUrl}`} alt={name || imageUrl} />
    </a>
  );
};
