import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./GalleryNav.module.css";

type Props = {
  leftClickHandler: React.MouseEventHandler<SVGSVGElement>;
  rightClickHandler: React.MouseEventHandler<SVGSVGElement>;
};

const GalleryNav = ({ leftClickHandler, rightClickHandler }: Props) => {
  return (
    <div className={styles.scroll}>
      <FontAwesomeIcon
        icon={faArrowCircleLeft}
        className={styles["scroll-button"]}
        onClick={leftClickHandler}
      />
      <FontAwesomeIcon
        icon={faArrowCircleRight}
        className={styles["scroll-button"]}
        onClick={rightClickHandler}
      />
    </div>
  );
};

export default GalleryNav;
