import { v4 as uuidv4 } from "uuid";

import MetroStopModel from "../../../models/metro-stop";
import Section from "../../UI/Section";
import MetroGrid from "./MetroGrid";
import styles from "./MetroNav.module.css";
import data from "../../../data/data.json"


const processedData: MetroStopModel[] = [];

for(const article of data){
  processedData.push({
    id: uuidv4(),
    label: article.label,
    label_left: article.label_left ?? false,
    events: article.events ? "active" : undefined,
    visual: article.visual ? "active" : undefined,
    writing: article.writing ? "active" : undefined,
    ideas: article.ideas ? "active" : undefined
  })
}

const MetroNav = () => {
  return (
    <Section className={styles.MetroNav}>
      <MetroGrid data={processedData} />
    </Section>
  );
};

export default MetroNav;
