export interface ArticleModel {
  id?: string;
  label: string;
  label_left?: boolean;
  // Description made temporarily optional, don't forget to make it mandatory later
  description?: string | string[];
  color: string;
  horizontal?: boolean;
  gallery?: GalleryTuple[];
  gallery_at_the_end?: boolean;
  content?: ArticleContent;
  visual?: boolean;
  events?: boolean;
  ideas?: boolean;
  writing?: boolean;
}

// Each section should contain multiple subsections, each subsection should have one or multiple paragraphs (hence array of strings) followed optionally by one or multiple images (hence array of strings - image addresses)
export type ArticleContent = ArticleSubsection[];

type ArticleSubsection = {
  title?: string;
  paragraphs?: ArticleSubsectionContent;
  images?: ArticleSubsectionImage[];
  notes?: string[];
};

type ArticleSubsectionImage = [string, string];

type ArticleSubsectionContent = string[] | string;

export type GalleryTuple = [string, string?];

// Type checking functions

const isGalleryTuple = (p: any) => {
  if (
    Array.isArray(p) &&
    (p.length === 1 || p.length === 2) &&
    p.every((i) => typeof i === "string")
  ) {
    return true;
  } else {
    return false;
  }
};

export const isGallery = (p: any) => {
  if (Array.isArray(p) && p.every((i) => isGalleryTuple(i))) {
    return true;
  } else {
    return false;
  }
};

const isArticleSubsectionContent = (p: any) => {
  if (
    typeof p === "string" ||
    (Array.isArray(p) && p.every((i) => typeof i === "string"))
  )
    return true;
  return false;
};

const isArticleSubsectionImage = (p: any) => {
  if (
    Array.isArray(p) &&
    p.length === 2 &&
    p.every((i) => typeof i === "string")
  )
    return true;
  return false;
};

const isArticleSubsection = (p: any) => {
  if (typeof p !== "object") return false;
  if (
    "paragraphs" in p &&
    (!Array.isArray(p.paragraphs) ||
      !p.paragraphs.every((i: any) => isArticleSubsectionContent(i))) &&
    typeof p.paragraphs !== "string"
  )
    return false;
  if ("title" in p && typeof p.title !== "string") return false;
  if (
    "images" in p &&
    (!Array.isArray(p.images) ||
      !p.images.every((i: any) => isArticleSubsectionImage(i)))
  )
    return false;
  if (
    "notes" in p &&
    (!Array.isArray(p.notes) ||
      !p.notes.every((i: any) => typeof i === "string"))
  )
    return false;
  return true;
};

export const isArticleContent = (p: any) => {
  if (Array.isArray(p) && p.every((i) => isArticleSubsection(i))) {
    return true;
  } else {
    return false;
  }
};

export const isArticleModel = (p: any): boolean => {
  if (typeof p !== "object" || !p) {
    return false;
  } else {
    if ("id" in p && typeof p.id !== "string") return false;
    if (!("label" in p) || typeof p.label !== "string") return false;
    if ("label_left" in p && typeof p.label_left !== "boolean") return false;
    if (
      "description" in p &&
      typeof p.description !== "string" &&
      Array.isArray(p.description) &&
      !p.description.every((e: any) => typeof e === "string")
    )
      return false;
    if (!("color" in p) || typeof p.color !== "string") return false;
    if ("horizontal" in p && typeof p.horizontal !== "boolean") return false;
    if ("gallery" in p && !isGallery(p.gallery)) return false;
    if ("gallery_at_the_end" in p && typeof p.gallery_at_the_end !== "boolean")
      return false;
    if ("content" in p && !isArticleContent(p.content)) return false;
    if ("visual" in p && typeof p.visual !== "boolean") return false;
    if ("events" in p && typeof p.events !== "boolean") return false;
    if ("ideas" in p && typeof p.ideas !== "boolean") return false;
    if ("writing" in p && typeof p.writing !== "boolean") return false;
  }
  return true;
};
