import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

import styles from "./Description.module.css";

type Props = {
  title: string;
  data: string | string[];
};

const Description = ({ title, data }: Props) => {
  let descriptionData: (JSX.Element | JSX.Element[] | string)[] = [];

  if (Array.isArray(data)) {
    for (let d of data) {
      descriptionData.push(
        parse(
          DOMPurify.sanitize(
            d.replace(
              /\[a=(.*?)\](.*?)\[\/a\]/g,
              '<a href="$1" target="_blank">$2</a>'
            ),
            { ADD_ATTR: ["target"] }
          )
        )
      );
    }
  } else {
    descriptionData.push(
      parse(
        DOMPurify.sanitize(
          data.replace(
            /\[a=(.*?)\](.*?)\[\/a\]/g,
            '<a href="$1" target="_blank">$2</a>'
          )
        )
      )
    );
  }

  return (
    <div>
      <h2>{title}</h2>
      {descriptionData.map((d, i) => {
        return (
          <p key={`desc_${i}`} className={styles.description}>
            {d}
          </p>
        );
      })}
    </div>
  );
};

export default Description;
