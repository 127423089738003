import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

import useScreenResolution from "../../../hooks/use-resolution";
import { ArticleContent } from "../../../models/article-model";
import styles from "./Content.module.css";
import DescriptionButton from "../Navigation/DescriptionButton";

type Props = {
  inputContent: ArticleContent;
  mobileDescriptionShow: React.MouseEventHandler<HTMLDivElement>;
  mobileGalleryActive: boolean;
};

const Content = ({
  inputContent,
  mobileDescriptionShow,
  mobileGalleryActive,
}: Props) => {
  const { width, height } = useScreenResolution();
  const mobile = width < 1020 || height < 760;

  const content = inputContent.map((subSection, subSectIndex) => {
    let elements: JSX.Element[] = [];
    if (subSection.title) {
      elements.push(<h3 key={`h3_${subSectIndex}`}>{subSection.title}</h3>);
    }
    if (subSection.paragraphs) {
      if (Array.isArray(subSection.paragraphs)) {
        for (let [i, p] of subSection.paragraphs.entries()) {
          let parsedP = parse(
            DOMPurify.sanitize(
              p
                .replace(/\[i\]/g, "<em>")
                .replace(/\[\/i\]/g, "</em>")
                .replace(/\[em\]/g, "<em>")
                .replace(/\[\/em\]/g, "</em>")
                .replace(/\[sup\]/g, "<sup>")
                .replace(/\[\/sup\]/g, "</sup>")
            )
          );
          elements.push(<p key={`par_${subSectIndex}_${i}`}>{parsedP}</p>);
        }
      } else {
        elements.push(
          <p key={`par_${subSectIndex}`}>{subSection.paragraphs}</p>
        );
      }
    }
    // Add specific className for notes later
    if (subSection.notes) {
      for (let [i, n] of subSection.notes.entries()) {
        let parsedN = parse(
          DOMPurify.sanitize(
            n
              .replace(/\[i\]/g, "<em>")
              .replace(/\[\/i\]/g, "</em>")
              .replace(/\[em\]/g, "<em>")
              .replace(/\[\/em\]/g, "</em>")
              .replace(/\[sup\]/g, "<sup>")
              .replace(/\[\/sup\]/g, "</sup>")
          )
        );
        elements.push(
          <p key={`note_${subSectIndex}_${i}`} className={styles.note}>
            {parsedN}
          </p>
        );
      }
    }
    if (subSection.images) {
      for (let [j, i] of subSection.images.entries()) {
        const fileType = i[0].substring(i[0].length - 3);
        let element: JSX.Element;

        if (fileType === "mp4") {
          element = (
            <video
              autoPlay
              playsInline
              loop
              muted
              key={`img_${subSectIndex}_${j}`}
            >
              <source
                src={process.env.REACT_APP_IMAGES_ROOT + i[0]}
                type="video/mp4"
                className={styles.image}
              />
              {i[1]}
            </video>
          );
        } else {
          element = (
            <img
              src={process.env.REACT_APP_IMAGES_ROOT + i[0]}
              alt={i[1]}
              key={`img_${subSectIndex}_${j}`}
              className={styles.image}
            />
          );
        }
        elements.push(element);
      }
    }
    return elements;
  });

  return (
    <>
      {mobile && mobileGalleryActive && (
        <DescriptionButton mobileDescriptionShow={mobileDescriptionShow} />
      )}
      {content}
    </>
  );
};

export default Content;
