import { v4 as uuidv4 } from "uuid";

import Section from "../../UI/Section";
import styles from "./About.module.css";
import Socials from "./Socials";
import Contact from "./Contact";
import { Customers, CustomerLogo } from "./Customers";
import { ClientModel } from "../../../models";

import data from "../../../data/clients.json";
import profile from "../../../assets/images/landing/profile-photo.png";

const clients: ClientModel[] = [];

for (const client of data) {
  clients.push({
    id: uuidv4(),
    name: client.name,
    url: client.url,
    logoUrl: client.logoUrl,
  });
}

const About = () => {
  return (
    <Section className={styles.about}>
      <h2>About me</h2>
      <div className={styles.avatar}>
        <img src={profile} alt="Vašek Kokeš" />
      </div>
      <p>
        My name is Vašek Kokeš and I am striving to be the one who boldly goes
        where no man has gone before. I work mainly as a graphic designer and
        event promoter. I also have a rich history as an artist and writer. Art
        is the main interest of my life, so I decided to study it thoroughly –
        practically in UMPRUM, Prague and theoretically at the Art history
        department of Philosophical Faculty of Charles University, Prague.
        Besides many of my own art projects, I helped to curate multiple
        exhibitions and wrote several theoretical texts about the theory of
        signs and other cultural phenomena. Additionally, I studied Czech
        architecture of the second half of the 20th century and wrote texts
        focusing on mass produced blocks of flats. Later, I studied a semester
        in Raumlabor Berlin studio and was honoured by the Czech-German Future
        Fond scholarship to study the history of architecture in Germany. In my
        professional life, I mainly focus on graphic design and visual identity
        across multiple media. By working for many leading companies in their
        specific markets as a freelance graphic designer, I was able to focus on
        delivering the best results to accommodate my client's visions. My
        experience as an event promoter led me to advise the Ministry of culture
        of Czech Republic and Soundczech in its efforts to support the
        alternative music scene in our country. Currently I work as a graphic
        designer and event producer at Fource Entertainment. I am always open to
        learning new things and working on new projects. If you would like to
        collaborate with me on a future project, please don’t hesitate to
        contact me.
      </p>
      <Socials
        // facebook="https://www.facebook.com/vasek.kokes"
        behance="https://www.behance.net/vasekkokes"
        linkedin="https://www.linkedin.com/in/vasekkokes"
        email="mailto:vasek.kokes@gmail.com"
        instagram="https://www.instagram.com/vasekkokes/"
      />
      <Customers>
        {clients.map((client) => (
          <CustomerLogo key={client.id} {...client} />
        ))}
      </Customers>
      <Contact />
    </Section>
  );
};

export default About;
