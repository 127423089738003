import { useEffect, useRef } from "react";

import Description from "./Description/Description";
import styles from "./Left.module.css";
import Navigation from "./Navigation/Navigation";
import useScreenResolution from "../../hooks/use-resolution";

type Props = {
  title: string;
  description: string | string[];
  color: string;
  mobileDescriptionHide: React.MouseEventHandler<HTMLDivElement>;
  mobileGalleryActive: boolean;
};

const Left = ({
  title,
  description,
  color,
  mobileDescriptionHide,
  mobileGalleryActive,
}: Props) => {
  const { height } = useScreenResolution();
  const mainLeftContentRef = useRef<HTMLDivElement>(null);

  // In mobile view, puts away fullscreen description and shows gallery
  useEffect(() => {
    if (mobileGalleryActive) {
      mainLeftContentRef.current?.classList.add(styles.inactive);
    } else {
      mainLeftContentRef.current?.classList.remove(styles.inactive);
    }
  }, [mobileGalleryActive]);

  const content = (
    <div
      className={styles.left}
      style={{ backgroundColor: color, height: height }}
      ref={mainLeftContentRef}
    >
      <Description title={title} data={description} />
      <Navigation mobileGalleryClick={mobileDescriptionHide} />
    </div>
  );

  return content;
};

export default Left;
