import { useState } from "react";
import { useParams } from "react-router-dom";

import Left from "../components/Article/Left";
import Right from "../components/Article/Right";
import Section from "../components/UI/Section";
import Error from "../components/Article/Error/Error";
import data from "../data/data.json";
import { ArticleModel, isArticleModel } from "../models/article-model";

const SingleArticle = () => {
  const [mobileGalleryActive, setMobileGalleryActive] = useState(false);

  const mobileGalleryClickHandler: React.MouseEventHandler<
    HTMLDivElement
  > = () => {
    setMobileGalleryActive((prev) => !prev);
  };
  // Get id from the address bar
  const { aid } = useParams();

  // Get data from our json
  const articleFetchedData = data.find(
    (item) => item.label.replace(/\W/g, "_") === aid
  );

  // Check data type and if correct, pass further, else it's undefined
  let articleData;
  if (articleFetchedData && isArticleModel(articleFetchedData)) {
    articleData = articleFetchedData as ArticleModel;
  }

  // If data checked passed, render our component
  if (articleData) {
    const articleProcessedData = {
      // If the article has id, use it, otherwise create from it's title
      // If we ever switch from json to database, we'll of course not use this approach anymore
      id: articleData.id
        ? articleData.id
        : articleData.label.replace(/\W/g, "_"),
      ...articleData,
    };

    return (
      <Section>
        <Left
          color={articleProcessedData.color}
          title={articleProcessedData.label}
          description={articleProcessedData.description!}
          mobileDescriptionHide={mobileGalleryClickHandler}
          mobileGalleryActive={mobileGalleryActive}
        />
        <Right
          content={articleProcessedData.content}
          gallery={articleProcessedData.gallery}
          horizontal={articleProcessedData.horizontal}
          mobileDescriptionShow={mobileGalleryClickHandler}
          mobileGalleryActive={mobileGalleryActive}
        />
      </Section>
    );
  }
  // else return error
  // TODO: #1 Error boundary
  return (
    <Section>
      <Error />
    </Section>
  );
};

export default SingleArticle;
