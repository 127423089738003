import { useEffect, useRef, useState } from "react";

import useScreenResolution from "../../../hooks/use-resolution";
import { GalleryTuple } from "../../../models/article-model";
import GalleryContainer from "./GalleryContainer";
import GalleryNav from "./GalleryNav";
import GalleryItem from "./GalleryItem";
import DescriptionButton from "../Navigation/DescriptionButton";
import styles from "./Gallery.module.css";

type Props = {
  gallery: GalleryTuple[];
  mobileDescriptionShow: React.MouseEventHandler<HTMLDivElement>;
  mobileGalleryActive: boolean;
  horizontal?: boolean;
};

// TODO: #5 Mobile version deformation

const Gallery = ({ gallery, horizontal, mobileDescriptionShow, mobileGalleryActive }: Props) => {
  // hooks
  const galleryRef = useRef<HTMLDivElement>(null);
  const { width, height } = useScreenResolution();
  const [scrolled, setScrolled] = useState<{ state: boolean; up: boolean }>({
    state: false,
    up: false,
  });

  // booleans for scrollAmount calculation
  const portrait = height > width;
  const mobile = width < 1020 || height < 760;
  const scrollAmount = mobile ? (portrait ? 0.51 : 0.25) : 0.35;

  // Menu click handlers
  const leftClickHandler = (e: React.MouseEvent<SVGSVGElement>) => {
    if (galleryRef.current) {
      galleryRef.current.scrollLeft -= scrollAmount * width;
    }
  };
  const rightClickHandler = (e: React.MouseEvent<SVGSVGElement>) => {
    if (galleryRef.current) {
      galleryRef.current.scrollLeft += scrollAmount * width;
    }
  };

  // Mouse scroll handling
  const horizontalScrollHandler = (e: React.WheelEvent<HTMLDivElement>) => {
    // only if we're horizontal, otherwise we want to keep default scrolling
    if (horizontal) {
      const x = e.deltaY;
      if (x > 0) {
        setScrolled({ up: false, state: true });
      } else {
        setScrolled({ up: true, state: true });
      }
    }
  };

  // Add snap class dynamically, so that the gallery doesn't render snapped in the middle
  useEffect(() => {
    if (galleryRef.current) {
      if (scrolled.state) {
        galleryRef.current.classList.add(styles.snap);
      }
    }
  }, [scrolled.state]);

  // This useEffect controlls scrolling
  useEffect(() => {
    if (galleryRef.current && scrolled.state) {
      if (scrolled.up) {
        galleryRef.current.scrollLeft -= 0.35 * width;
      } else {
        galleryRef.current.scrollLeft += 0.35 * width;
      }
    }
  }, [scrolled, width]);

  // Make a gallery out of our gallery data
  const parsedGallery: JSX.Element[] = [];

  for (const [i, galleryTuple] of gallery.entries()) {
    parsedGallery.push(
      <GalleryItem
        horizontal={horizontal ?? false}
        image={`${process.env.REACT_APP_IMAGES_ROOT || ''}${galleryTuple[0]}`}
        description={galleryTuple[1] ?? undefined}
        key={i}
      />
    );
  }

  const classes = styles.gallery + (horizontal ? "" : ` ${styles.vertical}`);

  return (
    <GalleryContainer horizontal={horizontal}>
      {(mobile && mobileGalleryActive) && (
        <DescriptionButton mobileDescriptionShow={mobileDescriptionShow} />
      )}
      <div
        className={classes}
        onWheel={horizontalScrollHandler}
        ref={galleryRef}
      >
        {parsedGallery}
      </div>
      {horizontal && (
        <GalleryNav
          leftClickHandler={leftClickHandler}
          rightClickHandler={rightClickHandler}
        />
      )}
    </GalleryContainer>
  );
};

export default Gallery;
