import styles from "./Contact.module.css";

const Contact = () => {
  return (
    <div className={styles.contact}>
      Václav Kokeš
      <br />
      Lucemburská 1497/6, 130 00, Praha 3 - Vinohrady
      <br />
      IČO: 02234131
    </div>
  );
};

export default Contact;
